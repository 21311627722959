import React, { MouseEventHandler, useMemo } from 'react';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import range from 'lodash/range';
import PageButton, { PageButtonProps } from './PageButton';
import {
  Navigation,
  NavButton,
  PageNavigationStyles,
  PageSelector,
} from './styles';

interface PageNavigationProps extends PageNavigationStyles, PageButtonProps {
  className?: string;
  onNextPage?: MouseEventHandler<HTMLDivElement>;
  onPreviousPage?: MouseEventHandler<HTMLDivElement>;
  pages?: number;
}

export default function PageNavigation({
  admin,
  className,
  onChange,
  onNextPage,
  onPreviousPage,
  page = 0,
  pages = 0,
}: PageNavigationProps) {
  const pageIterator = useMemo(() => range(pages), [pages]);

  return pages > 1 ? (
    <Navigation admin={admin} className={className}>
      <NavButton name="adminbarLt" disabled={page - 1 < 0} onClick={onPreviousPage} />
      <PageSelector>
        <NonKeyedListMapper list={pageIterator}>
          {(key: string, i: number) => (
            <PageButton
              admin={admin}
              key={key}
              active={i === page}
              onChange={onChange}
              page={i}
            />
          )}
        </NonKeyedListMapper>
      </PageSelector>
      <NavButton name="adminbarGt" disabled={page + 1 === pages} onClick={onNextPage} />
    </Navigation>
  ) : null;
}