import { connect } from 'react-redux';
import { disablePanels, enablePanels } from 'services/admin/actions';
import {
  TargetTypes,
} from 'services/admin/constants';
import {
  isSidebarVisible,
  getSidebarWidth,
} from 'services/user-layout/selectors';
import { logOut } from 'services/auth';
import {
  getHasAdminModal,
  getPreviewCardRenderer,
  isEditMode,
  getPreviewPanelRenderer,
  getMockPanelRenderer,
  getMockCardRenderer,
  getEditTarget,
  getAdminBarWidth,
} from 'services/admin/selectors';
import {
  getActivePanel,
  isChannelSelectorSidebarButtonVisible,
} from 'services/app/selectors';
import { setActivePanel } from 'services/app/actions';
import withErrorGuard from 'components/core/withErrorGuard';
import { isCurrentCardOpen } from 'services/card';
import { isDesktopLayout, isMobileLayout } from 'services/device';
import { getPanelState, isMobilePanelEmbed } from 'services/user-layout';
import { requestClosePanel } from 'services/custom-panels';
import { isSidebarModalOpened } from 'services/modals';
import { getRenderer } from 'services/renderer/selectors';
import { ID_SIDEBAR } from 'components/core/RendererProvider';
import { getCustomPanels } from 'services/custom-panels/selectors';
import { getStudioGuestModeInviteCode } from 'services/livestream/selectors';
import { getText300 } from 'services/themes';
import Sidebar from './view';

const mapStateToProps = state => ({
  activePanel: getActivePanel(state),
  adminBarWidth: getAdminBarWidth(state),
  blur: getHasAdminModal(state),
  customPanels: getCustomPanels(state),
  editing: getEditTarget(state) === TargetTypes.SIDEBAR,
  inEditMode: isEditMode(state),
  isCardOpen: isCurrentCardOpen(state),
  isChannelSelectorSidebarButtonVisible: isChannelSelectorSidebarButtonVisible(state),
  isDesktopLayout: isDesktopLayout(state),
  isLogoTarget: getEditTarget(state) === TargetTypes.LOGO,
  isMobileLayout: isMobileLayout(state),
  isMobilePanelEmbedLayout: isMobilePanelEmbed(state),
  isSidebarModalOpened: isSidebarModalOpened(state),
  isSidebarVisible: isSidebarVisible(state),
  mockCardRenderer: getMockCardRenderer(state),
  mockPanelRenderer: getMockPanelRenderer(state),
  otherRegionEditing: Boolean(
    isEditMode(state) &&
    getEditTarget(state) &&
    getEditTarget(state) !== TargetTypes.SIDEBAR,
  ),
  panelState: getPanelState(state),
  previewCardRenderer: getPreviewCardRenderer(state),
  previewPanelRenderer: getPreviewPanelRenderer(state),
  renderer: getRenderer(state, ID_SIDEBAR),
  sidebarWidth: getSidebarWidth(state),
  // render sidebar for logged out studio guest view
  studioInviteCode: getStudioGuestModeInviteCode(state),
  titleTextColor: getText300(state),
});

const mapDispatchToProps = dispatch => ({
  disablePanels: () => dispatch(disablePanels()),
  enablePanels: () => dispatch(enablePanels()),
  onCloseCustomPanel: () => dispatch(requestClosePanel()),
  onLogout: () => {
    dispatch(requestClosePanel());
    dispatch(logOut());
  },
  onSetActivePanel: id => dispatch(setActivePanel(id)),
});

export default withErrorGuard()(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar),
);
