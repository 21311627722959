import qs from 'qs';
import React, { MouseEvent, useCallback, useMemo } from 'react';
import { StyledExternalLink } from './styles';
import { ExternalLinkProps } from './types';

const ExternalLink = ({
  children,
  href,
  onClick: onLinkClick,
  plain = true,
  query,
  rel,
  target,
  ...props
}: ExternalLinkProps) => {
  const hrefValue = useMemo(() => {
    const queryString = qs.stringify(query);
    const sep = href!.includes('?') ? '&' : '?';
    return `${href}${queryString ? sep : ''}${queryString || ''}`;
  }, [
    JSON.stringify(query),
    href,
  ]);

  const onClick = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
    if (onLinkClick) {
      onLinkClick(event, hrefValue);
    }
  }, [
    hrefValue,
    onLinkClick,
  ]);

  const targetValue = target ? '_self' : (target || '_blank');

  return (
    <StyledExternalLink
      {...props}
      plain={plain}
      href={hrefValue}
      onClick={onClick}
      rel={rel === undefined ? 'noopener' : rel}
      target={targetValue}
    >
      {children}
    </StyledExternalLink>
  );
};

export default ExternalLink;
