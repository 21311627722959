import { ACCENT_PRIMARY, ALERT_ERROR, SURFACE_5, TEXT_200, TEXT_400 } from 'style/constants';
import { TEXT_LABEL_M_MEDIUM, TEXT_LABEL_S_MEDIUM } from 'style/design-system/textStyles';
import styled, { css } from 'styled-components';
import Icon from 'components/ui/Icon';
import { TextInput } from './TextInput';
import { PasswordInput } from './PasswordInput';

const INPUT_FOCUS = css`
  border-color: ${ACCENT_PRIMARY};

  & legend {
    color: ${ACCENT_PRIMARY};
  }
`;

const FieldSet = styled.fieldset<{ focused?: boolean; }>`
  border: 1px solid ${SURFACE_5};
  border-radius: 4px;
  position: relative;
  align-self: stretch;
  display: flex;
  padding: 4px 0 12px 16px;
  align-items: center;

  ${({ focused }) => focused && INPUT_FOCUS};
  &:focus-within {
    ${INPUT_FOCUS}
  }
`;

const Legend = styled.legend`
  padding-left: 4px;
  ${TEXT_LABEL_M_MEDIUM}
  color: ${TEXT_200};
`;

const SupportingText = styled.div.attrs(props => ({
  role: props.role ?? 'alert',
}))`
  display: flex;
  padding: 4px 0;
  padding-left: 16px;
  align-self: stretch;
  color: ${TEXT_400};
  ${TEXT_LABEL_S_MEDIUM}

  &:empty {
    display: none;
  }
`;

const InputIcon = styled(Icon).attrs(props => ({
  color: TEXT_200(props),
  tabIndex: -1,
}))<{ cursor?: string; }>`
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ cursor }) => cursor || 'default'};

  & svg {
    width: 24px;
    height: 24px;
  }
`;

const Root = styled.div<{ error?: boolean; }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ error }) => error && css`
    & fieldset {
      border-color: ${ALERT_ERROR};

      &:focus-within {
        border-color: ${ALERT_ERROR};

        & legend {
          color: ${ALERT_ERROR};
        }
      }
    }

    & legend {
      color: ${ALERT_ERROR};
    }

    & div[role="alert"] {
      color: ${ALERT_ERROR};
    }
  `}
`;

const FormInput = {
  FieldSet,
  Legend,
  TextInput,
  InputIcon,
  PasswordInput,
  SupportingText,
  Root,
};

export default FormInput;
