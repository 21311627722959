import styled, { css } from 'styled-components';
import AdminButton, { BASE_BUTTON, LargeButton } from 'components/admin2/ui/Button';
import {
  BACKGROUND_ACTIVE,
} from 'style/mixins';
import { TEXT_100, SURFACE_1, MAESTRO_WHITE, ACCENT_PRIMARY } from 'style/constants';
import EllipsisLoader from '../../admin2/ui/EllipsisLoader';
import Icon from 'components/ui/Icon';
import { ADMIN_TEXT_BODY_M_MEDIUM, ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_BODY_XS_BOLD, TEXT_BODY_XS_BOLD } from 'style/design-system/textStyles';
import { BaseButton } from '../Button';

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  z-index: 1900;
`;

export const Header = styled.div<{ showForAdmin?: boolean }>`
  ${ADMIN_TEXT_BODY_S_BOLD}
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 500;
  }
  position: relative;
  ${({ showForAdmin }) =>
    showForAdmin
      ? css`
          color: ${MAESTRO_WHITE};
        `
      : css`
          color: ${TEXT_100};
        `}
`;

export const StyledLoading = styled(EllipsisLoader).attrs((props) => ({
  color: ACCENT_PRIMARY(props),
}))`
  width: 100%;
  justify-content: center;
  padding: 20px;
`;

export const CloseButtonIcon = styled.div`
  background: ${SURFACE_1};
  cursor: pointer;
  width: 24px;
  height: 24px;
  background: black;
  border-radius: 4.54px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CloseIcon = styled(Icon)`
  ${ADMIN_TEXT_BODY_M_MEDIUM}
  color: rgba(0, 0, 0, 0.5);
  font-size: 0;
  svg {
    width: 8px;
    height: 8px;
  }
`;

export const HeaderActionsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const SubmitButton = styled(LargeButton).attrs({
  fullwidth: true,
})<{ showForAdmin?: boolean }>`
  ${({ showForAdmin }) =>
    showForAdmin
      ? css`
          ${BASE_BUTTON}
          ${ADMIN_TEXT_BODY_XS_BOLD}
        `
      : BACKGROUND_ACTIVE};
  margin-top: 10px;
`;

export const StyledAdminButton = styled(AdminButton)`
  ${ADMIN_TEXT_BODY_XS_BOLD}
  height: 22px;
  margin-right: 2px;
`;

export const StyledSiteButton = styled(BaseButton)`
  ${TEXT_BODY_XS_BOLD}
  height: 22px;
  margin-right: 2px;
`;
