import React, { MouseEventHandler, PropsWithChildren } from 'react';
import OptionalTranslated from 'components/admin2/ui/OptionalTranslated';
import {
  StyledAdminButton,
  Form,
  Header,
  HeaderActionsContainer,
  SubmitButton,
  CloseButtonIcon,
  CloseIcon,
  StyledLoading,
  StyledSiteButton,
} from './styles';
import type { TranslationKey } from 'hooks/use-translation';
export {
  CloseButtonIcon,
  CloseIcon,
} from './styles';

type FormProps = PropsWithChildren<{
  addCloseButton?: boolean;
  addCloseButtonClick?: MouseEventHandler<HTMLDivElement>;
  disabled?: boolean;
  headerKey?:TranslationKey;
  headerText?: string;
  loading?: boolean;
  onSubmit?: MouseEventHandler<HTMLButtonElement>;
  showForAdmin?: boolean;
  submitKey?:TranslationKey;
  submitText?: string;
  topButtonClick?: MouseEventHandler<HTMLButtonElement>;
  topButtonDisabled?:boolean;
  topButtonKey?:TranslationKey;
  topButtonText?: string;
}>;

export default function FormComponent({
  addCloseButton,
  addCloseButtonClick,
  children,
  disabled,
  headerKey,
  headerText,
  loading,
  onSubmit,
  showForAdmin,
  submitKey,
  submitText,
  topButtonClick,
  topButtonDisabled = false,
  topButtonKey,
  topButtonText,
}: FormProps) {

  return (
    <Form>
      <Header showForAdmin={showForAdmin}>
        {(headerText || headerKey) && (
          <OptionalTranslated stringKey={headerKey}>
            {headerText}
          </OptionalTranslated>
        )}
        <HeaderActionsContainer>
          {(topButtonText || topButtonKey) && (
              <OptionalTranslated
                autoFocus={false}
                component={showForAdmin ? StyledAdminButton : StyledSiteButton}
                onClick={topButtonClick}
                stringKey={topButtonKey}
                disabled={topButtonDisabled}
              >
                {topButtonText}
              </OptionalTranslated>
            )
          }
        {addCloseButton && (
          <CloseButtonIcon onClick={addCloseButtonClick}>
            <CloseIcon name="cancel-thin" />
          </CloseButtonIcon>
        )}
        </HeaderActionsContainer>
      </Header>
      {children}
      <StyledLoading loading={loading} />
      {!loading && (submitText || submitKey) && (
        <OptionalTranslated
          disabled={disabled}
          component={SubmitButton}
          onClick={onSubmit}
          stringKey={submitKey}
          showForAdmin={showForAdmin}
        >
          {submitText}
        </OptionalTranslated>
      )}
    </Form>
  );
}
