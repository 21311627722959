import { IFonts } from 'hooks/use-fonts';
import { ADMIN_ACCENT_PRIMARY, ADMIN_SURFACE_4, ADMIN_SURFACE_5, ADMIN_SURFACE_6, ADMIN_TEXT_100, SURFACE_6, TEXT_100, TEXT_200 } from 'style/constants';
import { ADMIN_TEXT_LABEL_M_MEDIUM, TEXT_BODY_M_REGULAR } from 'style/design-system/textStyles';
import styled, { css } from 'styled-components';

export const TOOLBAR_BUTTON_ACTIVE_CSS = css`
  background-color: ${ADMIN_SURFACE_5};
  border-color: ${ADMIN_ACCENT_PRIMARY};
`;

export const ToolbarButton = styled.button<{ active?: boolean; }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-width: 50px;
  border-radius: 8px;
  border: 1px solid ${ADMIN_SURFACE_6};
  color: ${ADMIN_TEXT_100};
  ${ADMIN_TEXT_LABEL_M_MEDIUM}

  &:hover {
    ${TOOLBAR_BUTTON_ACTIVE_CSS}
  }

  & svg {
    width: 24px;
    height: 24px;
  }

  ${({ active }) => active && TOOLBAR_BUTTON_ACTIVE_CSS}
  ${({ disabled }) => disabled && css`
    pointer-events: none;
    opacity: 0.5;
    border: none;
  `}
`;


export const Toolbar = styled.div`
  cursor: default;
  display: flex;
  column-gap: 24px;
  row-gap: 4px;
  padding: 10px;
  background-color: ${ADMIN_SURFACE_4};
  border: 1px solid ${ADMIN_SURFACE_6};
  border-radius: 5px;
  flex-wrap: wrap;
`;

export const ToolbarSection = styled.div`
  display: flex;
  gap: 4px;
`;

const RichTextHeadingBase = css`
  color: ${TEXT_100};
`;

export const StylesWrapper = styled.div<{ fonts: IFonts[]; inline?: boolean; isEditing: boolean; }>`
  width: 100%;
  position: relative;
  ${TEXT_BODY_M_REGULAR}
  color: ${TEXT_200};

  & .mce-content-body {
    line-height: normal;
    cursor: ${({ isEditing }) => isEditing ? 'text' : 'default'};

    ${({ inline }) => inline && css`
      & .EditorTheme__paragraph, & .EditorTheme__h1, & .EditorTheme__h2, & .EditorTheme__h3, & .EditorTheme__h4, & .EditorTheme__h5, & .EditorTheme__h6 {
        margin: 0;
        font-weight: 400;
      }
    `}
  }

  ${({ fonts }) => fonts.map(font => css`
    @font-face {
        font-family: ${font.name};
        src: url(${font.baseUrl}.eot);
        src: local('☺'),
             url(${font.baseUrl}.woff) format('woff'),
             url(${font.baseUrl}.ttf) format('truetype'),
             url(${font.baseUrl}.svg) format('svg');
        font-weight: normal;
        font-style: normal;
    }
  `)}

  & .EditorTheme__textBold {
    font-weight: 700;
  }

  & .EditorTheme__textItalic {
    font-style: italic;
  }

  & .EditorTheme__textUnderline {
    text-decoration: underline;
  }

  & .EditorTheme__textStrikethrough {
    text-decoration: line-through;
  }

  & .EditorTheme__textSubscript {
    vertical-align: sub;
  }

  & .EditorTheme__textSuperscript {
    vertical-align: super;
  }

  & .EditorTheme__code {
    font-family: monospace;
  }

  & .EditorTheme__textStrikethrough {
    text-decoration: underline line-through;
  }

  & .EditorTheme__blockCursor {
    background-color: #007bff;
    color: white;
  }

  & .EditorTheme__characterLimit {
    color: red;
  }

  & .EditorTheme__code {
    font-family: monospace;
  }

  & .EditorTheme__embedBlock {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 10px;
  }

  & .EditorTheme__embedBlockFocus {
    background-color: #e0e0e0;
    border: 1px solid #aaa;
    padding: 10px;
  }

  & .EditorTheme__hashtag {
    color: #007bff;
  }

  & .EditorTheme__h1 {
    ${RichTextHeadingBase}
    font-size: 32px;
  }

  & .EditorTheme__h2 {
    ${RichTextHeadingBase}
    font-size: 24px;
  }

  & .EditorTheme__h3 {
    ${RichTextHeadingBase}
    font-size: 20px;
  }

  & .EditorTheme__h4 {
    ${RichTextHeadingBase}
    font-size: 18px;
  }

  & .EditorTheme__h5 {
    ${RichTextHeadingBase}
    font-size: 16px;
  }

  & .EditorTheme__h6 {
    ${RichTextHeadingBase}
    font-size: 14px;
  }

  & .editor-image {
    max-width: 100%;
  }

  & .EditorTheme__indent {
    padding-left: 20px;
  }

  & .EditorTheme__link {
    color: #007bff;
  }

  & .EditorTheme__checklist {
    list-style-type: none;
  }

  & .EditorTheme__listItem {
    margin: 0;
  }

  & .EditorTheme__nestedListItem {
    padding-left: 2em;
  }

  & .EditorTheme__ol1 {
    padding-left: 2em;
    margin: 0;
  }

  & .EditorTheme__ol2 {
    padding-left: 4em;
    margin: 0;
  }

  & .EditorTheme__ol3 {
    padding-left: 6em;
    margin: 0;
  }

  & .EditorTheme__ol4 {
    padding-left: 8em;
    margin: 0;
  }

  & .EditorTheme__ol5 {
    padding-left: 10em;
    margin: 0;
  }

  & .EditorTheme__ol6 {
    padding-left: 12em;
    margin: 0;
  }

  & .EditorTheme__ol7 {
    padding-left: 14em;
    margin: 0;
  }

  & .EditorTheme__ol8 {
    padding-left: 16em;
    margin: 0;
  }

  & .EditorTheme__ol9 {
    padding-left: 18em;
    margin: 0;
  }

  & .EditorTheme__ul {
    list-style-type: disc;
    margin: 0;
  }

  & .EditorTheme__ltr {
    direction: ltr;
  }

  & .EditorTheme__mark {
    background-color: yellow;
  }

  & .EditorTheme__markOverlap {
    background-color: yellow;
  }

  & .EditorTheme__table {
    width: 100%;
    table-layout: fixed;
    overflow-x: scroll;
    border-spacing: 0;
    border-collapse: collapse;

    & p {
      margin: 0;
    }

    & .EditorTheme__tableCell {
      padding: 10px;
      border: 1px solid ${SURFACE_6};
      overflow: hidden;
      outline: none;
      line-break: normal;
    }
  }

  & .TableNode__contentEditable {
    min-height: 20px;
    border: 0px;
    resize: none;
    cursor: text;
    display: block;
    position: relative;
    tab-size: 1;
    outline: 0px;
    padding: 0;
    user-select: text;
    font-size: 15px;
    white-space: pre-wrap;
    word-break: break-word;
    z-index: 3;
  }
`;
