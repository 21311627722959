import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'hooks';
import { useSelector } from 'react-redux';
import { getHomeId, getSiteId } from 'services/app/selectors';
import { triggerPageChange } from 'services/app/actions';
import { getPrimaryToken } from 'services/auth';
import { getDevicePermanentId } from 'services/device';
import { setGateSubscriptions } from 'services/gate';
import { getActiveSiteFeatures } from 'services/app/selectors/common';
import { Feature } from 'services/feature-gate';
import { PAGE_SERVICE_BASE_URL, PAGE_SERVICE_V3_BASE_URL } from 'config';
import { camelify } from 'shared/string-utils';
import { IObject } from 'models';
import IState from 'services/state';
import maestroApiRequest from 'services/maestro-api-client';
import axios from 'axios';

export const useFetchContent = (
  getObjectSlug: (state: IState) => string | null,
  onObjectUpdate: (doc: any, isLoaded: any) => void,
  handleObjectUpdate: (object: any, isLoaded: any) => void,
  scrollContainerRef: MutableRefObject<HTMLDivElement | null>,
  loaded: boolean,
) => {
  const dispatch = useDispatch();

  const features = useSelector(getActiveSiteFeatures);
  const isPagesV3Enabled = Boolean(features[Feature.PAGES_V3]);

  const [targetObjectId, setTargetObjectId] = useState<string | null>(null);
  const deviceId = useSelector(getDevicePermanentId);
  const homeId = useSelector(getHomeId);
  const primaryToken = useSelector(getPrimaryToken);
  const siteId = useSelector(getSiteId);
  const targetObjectSlug = useSelector(getObjectSlug);

  // If slug or collection change, or if we're on home and home changes, we need to clear
  // out the object and show the loading spinner.
  const prevHomeState = useRef({
    targetObjectSlug,
    homeId,
  });

  useEffect(() => {
    const isHome = !targetObjectSlug;
    const shouldClear =
      targetObjectSlug !== prevHomeState.current.targetObjectSlug ||
      (isHome && homeId !== prevHomeState.current.homeId);

    prevHomeState.current = {
      targetObjectSlug,
      homeId,
    };

    if (shouldClear && !loaded) {
      onObjectUpdate(null, false);
    }
  }, [targetObjectSlug, homeId, onObjectUpdate]);

  useEffect(() => {
    let cleanup = () => {
      // placeholder
    };

    const realtimeDocumentId = targetObjectSlug ? targetObjectId : homeId;

    if (!(targetObjectSlug || realtimeDocumentId)) {
      return;
    }

    const pageServiceURL = isPagesV3Enabled
      ? PAGE_SERVICE_V3_BASE_URL
      : PAGE_SERVICE_BASE_URL;

    const pageUrl = targetObjectSlug
      ? `${pageServiceURL}/content/slug/${targetObjectSlug}`
      : `${pageServiceURL}/content/id/${realtimeDocumentId}`;

    (async () => {
      let aborted = false;

      try {
        dispatch(triggerPageChange());

        const { data: pageApiResponse } = await maestroApiRequest({
          freshInstance: true,
          primaryToken,
          siteId,
        }).get(pageUrl, {
          headers: {
            'x-maestro-device-id': deviceId,
          },
          cancelToken: new axios.CancelToken((canceler) => {
            cleanup = () => {
              aborted = true;
              canceler();
            };
          }),
        });

        const { channel: page } = camelify(pageApiResponse) as {
          channel: IObject;
        };

        const { data } = page;

        dispatch(setGateSubscriptions(data?.gate?.gate));

        if (!page._id) {
          handleObjectUpdate(null, true);
        } else {
          onObjectUpdate(page, true);
        }

        // add realtime listener to channel doc
        setTargetObjectId(page?._id || null);
        scrollContainerRef.current?.scrollTo({ top: 0 });
      } catch (error) {
        if (aborted) {
          return;
        }

        // tslint:disable:next-line no-console
        console.error('Error fetching page content: ', error);
      }
    })();

    return cleanup;
  }, [
    primaryToken,
    targetObjectSlug,
    targetObjectId,
    homeId,
    siteId,
    deviceId,
    handleObjectUpdate,
    onObjectUpdate,
    isPagesV3Enabled,
  ]);
};
