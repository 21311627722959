import styled, { css } from 'styled-components';
import Icon from 'components/ui/Icon';
import { CIRCLE } from 'style/mixins';
import {
  TEXT_100,
  ADMIN_TEXT_100,
} from 'style/constants';

export interface PageNavigationStyles {
  active?: boolean;
  admin?: boolean;
}

export const Navigation = styled.div<PageNavigationStyles>`
  display: flex;
  & span {
    display: flex;
    align-items: center;
    cursor: pointer;
    & svg {
      width: 13px;
      height: 13px;
      & path {
        fill: ${({ admin, ...props }) => admin ? ADMIN_TEXT_100 : TEXT_100(props)};
      }
    }
  }
`;

export const NavButton = styled(Icon)<{disabled?: boolean}>`
  ${({ disabled }) => disabled && css`
    opacity: 0.5;
    pointer-events: none;
  `}
`;

export const PageSelector = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const PageButton = styled.div.attrs(() => ({
  radius: '7px',
}))<PageNavigationStyles>`
  ${CIRCLE}
  margin: 0 4px;
  ${({ active }) => css`
    opacity: ${active ? 1 : 0.2};
    cursor: ${active ? 'auto' : 'pointer'};
  `}
  background: ${({ admin }) => admin ? ADMIN_TEXT_100 : TEXT_100};
`;
