import Icon from 'components/ui/Icon';
import {
  ADMIN_ACCENT_PRIMARY,
  ADMIN_SURFACE_3,
  ADMIN_SURFACE_4,
  ADMIN_TEXT_100,
} from 'style/constants';
import { ADMIN_TEXT_LABEL_S_MEDIUM } from 'style/design-system/textStyles';
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components';

export const TableActionMenuWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
`;

export const AddRowOrColumnButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  &:hover {
    svg {
      path {
        fill: ${ADMIN_ACCENT_PRIMARY};
      }
    }
  }
`;

export const TableCellActionButton = styled.button`
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  position: relative;
  border-radius: 15px;
  display: inline-block;
  cursor: pointer;
`;

export const ThreeDotsIcon = styled(Icon).attrs((props) => ({
  name: 'threeDots',
  color: ADMIN_TEXT_100(props),
}))`
  svg {
    width: 16px;
    height: 16px;
  }
`;

export const PlusIcon = styled(Icon).attrs((props) => ({
  name: 'plus',
  color: ADMIN_TEXT_100(props),
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 12px;
    height: 12px;
  }
`;

export const DropdownWrapper = styled.div`
  z-index: 100;
  display: flex;
  flex-direction: column;
  position: fixed;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  min-height: 40px;
  background-color: ${ADMIN_SURFACE_3};
  width: 192px;
`;

export const DropdownItem = styled.button`
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 10px 15px;
  align-items: center;
  background-color: ${ADMIN_SURFACE_4};
  &:hover {
    background-color: ${ADMIN_SURFACE_3};
  }
`;

export const ItemText = styled.span`
  color: ${ADMIN_TEXT_100};
  ${ADMIN_TEXT_LABEL_S_MEDIUM};
`;

export const DeleteIcon = styled(Icon).attrs((props) => ({
  name: 'trashV2',
  color: ADMIN_TEXT_100(props),
}))`
  svg {
    width: 16px;
    height: 16px;
  }
`;

interface IAddToTableElementIconProps {
  rotateX?: number;
  rotateY?: number;
}

export const AddTableElementIcon = styled(Icon).attrs(props => ({
  name: 'addTableNode',
  color: ADMIN_TEXT_100(props),
}))`
  svg {
    width: 16px;
    height: 16px;
  }
  ${({ rotateX }: IAddToTableElementIconProps) => rotateX && `transform: rotateX(${rotateX * 90}deg)`};
  ${({ rotateY }: IAddToTableElementIconProps) => rotateY && `transform: rotateY(${rotateY * 90}deg)`};
  will-change: transform;
`;
