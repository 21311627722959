import { LinkNode, SerializedLinkNode } from '@lexical/link';
import { EditorConfig, Spread } from 'lexical';

export type SerializedExtendedLinkNode = Spread<
  {
    linkColor: string;
    type: string;
  },
  SerializedLinkNode
>;

export default class ExtendedLinkNode extends LinkNode {
  linkColor: string;
  url: string;

  constructor(url: string, linkColor: string) {
    super(url);
    this.url = url;
    this.linkColor = linkColor;
  }

  static clone(node: ExtendedLinkNode): ExtendedLinkNode {
    return new ExtendedLinkNode(node.url, node.linkColor);
  }

  static getType() {
    return 'extended-link';
  }

  static importJSON(serializedNode: SerializedExtendedLinkNode): ExtendedLinkNode {
    return new ExtendedLinkNode(serializedNode.url, serializedNode.linkColor);
  }

  createDOM(config: EditorConfig) {
    const anchorElement = super.createDOM(config);
    anchorElement.style.color = this.getLinkColor();
    return anchorElement;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      type: ExtendedLinkNode.getType(),
      linkColor: this.getLinkColor(),
    };
  }

  getLinkColor() {
    const latest = this.getLatest();
    return latest.linkColor;
  }

  setLinkColor(color: string) {
    const latest = this.getWritable();
    latest.linkColor = color;
  }

  updateDOM(): boolean {
    return true;
  }
}

export const $isExtendedLinkNode = (node: any): node is ExtendedLinkNode => {
  return node instanceof ExtendedLinkNode;
};
